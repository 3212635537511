<template>
  <div class="brochures">
    <div class="content">
      <div class="title">Брошури Тева</div>

      <div class="item-wrapper">
        <router-link class="item" v-for="(key, value ) in games" :to="`${key.link}`" :key="value">
          <img :src="require(`@/assets/img/brochures/${key.img}`)" alt="">
          <div :class="`item-block ${key.color}`">
            <svg viewBox="0 0 238 238" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g opacity="0.8">
                <mask id="mask0_1703_16333" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="238"
                      height="238">
                  <circle cx="119" cy="119" r="119" fill="#D9D9D9"/>
                </mask>
                <g mask="url(#mask0_1703_16333)">
                  <path d="M-30.5801 1041.06L861.94 233.09" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-30.6001 1035.02L861.93 227.05" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-30.6201 1028.98L861.91 221.01" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-30.6299 1022.94L861.89 214.97" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-30.6499 1016.9L861.87 208.93" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-30.6699 1010.86L861.85 202.89" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-30.6899 1004.83L861.83 196.86" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-30.71 998.79L861.82 190.82" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-30.73 992.75L861.8 184.78" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-30.75 986.71L861.78 178.74" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-30.7598 980.67L861.76 172.7" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-30.7798 974.63L861.74 166.66" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-30.7998 968.59L861.72 160.62" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-30.8198 962.55L861.71 154.58" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-30.8398 956.52L861.69 148.55" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-30.8599 950.48L861.67 142.51" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-30.8701 944.44L861.65 136.47" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-30.8901 938.4L861.63 130.43" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-30.9102 932.36L861.61 124.39" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-30.9302 926.32L861.6 118.35" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-30.9502 920.281L861.58 112.311" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-30.9702 914.24L861.56 106.271" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-30.98 908.2L861.54 100.23" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-31 902.17L861.52 94.2002" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-31.02 896.13L861.5 88.1602" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-31.04 890.09L861.48 82.1201" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-31.0601 884.05L861.47 76.0801" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-31.0801 878.01L861.45 70.04" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-31.0898 871.97L861.43 64" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-31.1099 865.93L861.41 57.96" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-31.1299 859.89L861.39 51.9204" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-31.1499 853.86L861.37 45.8901" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-31.1699 847.82L861.36 39.8501" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-31.1899 841.781L861.34 33.8105" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-31.2002 835.74L861.32 27.7705" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-31.2202 829.7L861.3 21.7305" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-31.2402 823.661L861.28 15.6904" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-31.2598 817.62L861.26 9.65039" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-31.2798 811.58L861.25 3.61035" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-31.2998 805.54L861.23 -2.42969" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-31.3101 799.51L861.21 -8.45996" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-31.3301 793.47L861.19 -14.5" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-31.3501 787.43L861.17 -20.54" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-31.3701 781.39L861.15 -26.5796" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-31.3901 775.35L861.14 -32.6196" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-31.4102 769.31L861.12 -38.6597" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-31.4199 763.27L861.1 -44.6997" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-31.4399 757.23L861.08 -50.7397" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-31.46 751.2L861.06 -56.7695" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-31.48 745.161L861.04 -62.8096" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-31.5 739.12L861.03 -68.8496" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-31.52 733.08L861.01 -74.8896" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-31.5298 727.04L860.99 -80.9297" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-31.5498 721L860.97 -86.9697" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-31.5698 714.96L860.95 -93.0098" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-31.5898 708.92L860.93 -99.0498" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-31.6099 702.89L860.92 -105.09" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-31.6299 696.85L860.9 -111.12" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-31.6401 690.81L860.88 -117.16" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-31.6602 684.77L860.86 -123.2" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-31.6802 678.73L860.84 -129.24" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-31.7002 672.69L860.82 -135.28" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-31.7202 666.65L860.81 -141.32" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-31.7402 660.61L860.79 -147.36" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-31.7598 654.57L860.77 -153.4" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-31.77 648.54L860.75 -159.43" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-31.79 642.5L860.73 -165.47" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-31.8101 636.46L860.71 -171.51" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-31.8301 630.42L860.7 -177.55" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-31.8501 624.38L860.68 -183.59" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-31.8701 618.34L860.66 -189.63" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-31.8799 612.3L860.64 -195.67" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-31.8999 606.26L860.62 -201.71" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-31.9199 600.23L860.6 -207.74" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-31.9399 594.19L860.59 -213.78" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-31.96 588.15L860.57 -219.82" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-31.98 582.11L860.55 -225.86" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-31.9902 576.07L860.53 -231.9" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-32.0098 570.03L860.51 -237.94" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-32.0298 563.99L860.49 -243.979" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-32.0498 557.95L860.47 -250.02" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-32.0698 551.91L860.46 -256.06" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-32.0898 545.88L860.44 -262.09" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-32.1001 539.84L860.42 -268.13" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-32.1201 533.8L860.4 -274.17" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-32.1401 527.76L860.38 -280.21" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-32.1602 521.72L860.36 -286.25" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-32.1802 515.68L860.35 -292.29" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-32.2002 509.64L860.33 -298.33" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-32.21 503.6L860.31 -304.37" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-32.23 497.57L860.29 -310.4" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-32.25 491.53L860.27 -316.44" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-32.27 485.49L860.25 -322.479" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-32.29 479.45L860.24 -328.52" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-32.3101 473.41L860.22 -334.56" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-32.3198 467.37L860.2 -340.6" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-32.3398 461.33L860.18 -346.64" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-32.3599 455.29L860.16 -352.68" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-32.3799 449.26L860.14 -358.72" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-32.3999 443.22L860.13 -364.75" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-32.4199 437.18L860.11 -370.79" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-32.4302 431.14L860.09 -376.83" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-32.4502 425.1L860.07 -382.87" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-32.4702 419.06L860.05 -388.91" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-32.4902 413.02L860.03 -394.95" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-32.5098 406.98L860.02 -400.99" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-32.5298 400.94L860 -407.03" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-32.54 394.91L859.98 -413.06" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-32.5601 388.87L859.96 -419.1" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-32.5801 382.83L859.94 -425.14" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-32.6001 376.79L859.92 -431.18" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-32.6201 370.75L859.91 -437.22" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-32.6401 364.71L859.89 -443.26" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-32.6499 358.67L859.87 -449.3" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-32.6699 352.63L859.85 -455.34" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-32.6899 346.6L859.83 -461.37" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-32.71 340.56L859.81 -467.41" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-32.73 334.52L859.8 -473.45" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-32.75 328.48L859.78 -479.49" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-32.77 322.44L859.76 -485.53" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-32.7798 316.4L859.74 -491.57" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-32.7998 310.36L859.72 -497.61" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-32.8198 304.32L859.7 -503.65" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-32.8398 298.28L859.69 -509.69" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-32.8599 292.25L859.67 -515.72" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-32.8799 286.21L859.65 -521.76" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-32.8901 280.17L859.63 -527.8" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-32.9102 274.13L859.61 -533.84" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-32.9302 268.09L859.59 -539.88" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-32.9502 262.05L859.58 -545.92" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-32.9702 256.01L859.56 -551.96" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-32.9902 249.97L859.54 -558" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-33 243.94L859.52 -564.03" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-33.02 237.9L859.5 -570.07" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-33.04 231.86L859.48 -576.11" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-33.0601 225.82L859.46 -582.15" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-33.0801 219.78L859.45 -588.19" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-33.1001 213.74L859.43 -594.229" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-33.1099 207.7L859.41 -600.27" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-33.1299 201.66L859.39 -606.31" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-33.1499 195.62L859.37 -612.35" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-33.1699 189.59L859.35 -618.38" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-33.1899 183.55L859.34 -624.42" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-33.21 177.51L859.32 -630.46" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-33.2202 171.47L859.3 -636.5" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-33.2402 165.431L859.28 -642.54" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-33.2598 159.39L859.26 -648.58" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-33.2798 153.35L859.24 -654.62" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-33.2998 147.31L859.23 -660.66" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-33.3198 141.28L859.21 -666.69" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-33.3301 135.24L859.19 -672.729" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-33.3501 129.2L859.17 -678.77" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-33.3701 123.16L859.15 -684.81" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-33.3901 117.12L859.13 -690.85" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-33.4102 111.08L859.12 -696.89" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-33.4302 105.04L859.1 -702.93" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-33.4399 99.0002L859.08 -708.97" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-33.46 92.9702L859.06 -715.01" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-33.48 86.9305L859.04 -721.04" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-33.5 80.8904L859.02 -727.08" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-33.52 74.8503L859.01 -733.12" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-33.54 68.8104L858.99 -739.16" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
                  <path d="M-33.5498 62.7703L858.97 -745.2" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-33.5698 56.7302L858.95 -751.24" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-33.5898 50.6902L858.93 -757.28" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-33.6099 44.6502L858.91 -763.32" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-33.6299 38.6204L858.9 -769.35" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-33.6499 32.5803L858.88 -775.39" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-33.6602 26.5403L858.86 -781.43" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-33.6802 20.5002L858.84 -787.47" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-33.7002 14.4602L858.82 -793.51" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-33.7202 8.42023L858.8 -799.55" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                  <path d="M-33.7402 2.38013L858.79 -805.59" stroke="#A5CF4C" stroke-width="0.75"
                        stroke-miterlimit="10"/>
                </g>
              </g>
            </svg>
            <p v-html="key.text"></p>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "brochures",

  data: () => ({

    games: [
      {
        link: "/brochures/medicines-are-available",
        img: "medicines-are-available.png",
        text: "Тева у програмі<br>«Доступні ліки»",
        color: "green"
      },
      {
        link: "/brochures/estsitalopram",
        img: "estsitalopram.png",
        text: "Лінійка бренду Тева.<br>Есциталопрам-Тева",
        color: "green"
      },
      {
        link: "/brochures/lercanidipin",
        img: "lercanidipin.png",
        text: "Лінійка бренду Тева.<br>Лерканідипін-Тева",
        color: "teal"
      },
      {
        link: "/brochures/pantoprazol",
        img: "pantoprazol.png",
        text: "Лінійка бренду Тева.<br>Пантопразол-Тева",
        color: "blue"
      },
      {
        link: "/brochures/budesonid",
        img: "budesonid.jpg",
        text: "Лінійка бренду Тева.<br>Будесонід-Тева",
        color: "green"
      },
      {
        link: "/brochures/ramipril",
        img: "ramipril.jpg",
        text: "Лінійка бренду Тева.<br>Раміприл-Тева",
        color: "teal"
      },
      {
        link: "/brochures/rozuvastatin",
        img: "rozuvastatin.jpg",
        text: "Лінійка бренду Тева.<br>Розувастатин-Тева",
        color: "blue"
      },
      // {
      //   link: "/brochures/valsartan-h",
      //   img: "valsartan_h.jpg",
      //   text: "Лінійка бренду Тева.<br>Валсартан Н-Тева",
      //   color: "green"
      // },
      {
        link: "/brochures/valsartan",
        img: "valsartan.jpg",
        text: "Лінійка бренду Тева.<br>Валсартан-Тева",
        color: "teal"
      },
      {
        link: "/brochures/AH_treatment_scheme",
        img: "treatment.jpg",
        text: "Схема лікування артеріальної гіпертензії: перший...",
        color: "blue"
      },
    ]
  }),
}
</script>

<style scoped lang="scss">

@import "../assets/style/fonts.css";
@import "../assets/style/_vars.scss";

.brochures {
  position: relative;

  max-width: 1270px;
  width: 100%;
  margin: 0 auto;

  min-height: calc(100vh);
  min-height: calc((var(--vh, 1vh) * 100) - var(--height_content));

  padding: 40px 20px;

  @media screen and (max-width: 768px) {
    padding: 29px 20px;

  }

  .content {
    max-width: 1240px;
    width: 100%;
    margin: 0 auto 0 auto;

    @media screen and (max-width: 768px) {
      margin-bottom: 0;
    }

    .title {
      font-family: $B;
      font-size: 48px;
      color: $grey;
      margin-bottom: 20px;
      @media screen and (max-width: 560px) {
        font-size: 24px;
        line-height: 33px;
      }
    }

    .item-wrapper {
      display: flex;
      flex-flow: row wrap;
      margin: 0 -10px;

      @media screen and (max-width: 720px) {
        margin: 0 -5px;
      }


      .item {
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        max-width: calc(25% - 20px);
        width: 100%;
        margin: 10px;
        position: relative;
        text-decoration: none;

        @media screen and (max-width: 720px) {
          margin: 5px;
        }

        @media screen and (max-width: 1024px) {
          max-width: calc((100% / 3) - 20px);
        }
        @media screen and (max-width: 720px) {
          max-width: calc((100% / 2) - 10px);
        }
        @media screen and (max-width: 380px) {
          max-width: calc(100% - 10px);
        }


        img {
          width: 100%;
          height: auto;
          display: block;
        }

        .item-block {
          overflow: hidden;
          position: relative;

          height: 144px;
          padding: 30px 20px;
          margin-top: -1px;

          @media screen and (max-width: 720px) {
            height: 100px;
            padding: 15px 10px;
          }

          &.green {
            background: #00A03B;

            svg {
              left: -100px;

              top: 50px;
            }
          }

          &.teal {
            background: $teal;

            svg {
              left: -100px;

              top: 50px;
              display: none;

              path {

              }
            }
          }

          &.blue {
            background: $blue;

            svg {
              right: -70px;

              top: -150px;

              path {
                stroke: #2881AB;
              }
            }
          }

          svg {
            position: absolute;
            z-index: 1;
            display: block;
            width: 238px;
            height: auto;
          }

          p {
            font-family: $B;
            position: relative;
            z-index: 2;
            font-size: 20px;
            line-height: 120%;
            color: #FFFFFF;
            @media screen and (max-width: 720px) {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
</style>